import styled from "@emotion/styled"
import React from "react"
import "normalize.css"
import "./layout.css"

const OuterContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  padding: 0 16px 16px;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 500px;
`

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <OuterContainer>
      <InnerContainer>{children}</InnerContainer>
      <noscript>
        <img src="https://sn.rynftz.gr/ingress/7f69003f-78da-4ae3-abbd-25dfc6d3a1e6/pixel.gif" />
      </noscript>
      <script defer src="https://sn.rynftz.gr/ingress/7f69003f-78da-4ae3-abbd-25dfc6d3a1e6/script.js" />
    </OuterContainer>
  )
}
